<template>
    <div></div>
</template>
<script>
import {
    saveQueueANDMR,
    getCallType,
    getMidByPid,
    call_p2p,
    getMidByDid,
} from '@/api/user'

export default {
    props: {
        callActive: {
            type: Boolean,
            required: true,
        },
        payload: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            phone_status: 0,
            currentday: this.$moment(new Date(), 'YYYY-MM-DD').format(
                'YYYY-MM-DD'
            ),
        }
    },
    computed: {
        qid() {
            return this.payload.qid
        },
        spid() {
            return this.payload.spid
        },
        pname() {
            return this.payload.pname
        },
        sdid() {
            return this.payload.sdid
        },
        did() {
            return this.$store.getters.did
        },
        dname() {
            return this.payload.dname
        },
        device() {
            return this.$store.getters.device
        },
        partnerid() {
            return this.$store.getters.partner
        },
        isDev() {
            return this.$store.getters.isDev
        },
        doctorType() {
            return this.$store.state.doctortype
        },
        dmid() {
            return this.$store.state.mid
        },
    },
    watch: {
        callActive(v) {
            if (v == true) this.openLoadingContained()
        },
    },
    methods: {
        // 掛號卡通話 加上loading防呆 & 檢查網頁版裝置 然後打給病人 callOut
        openLoadingContained() {
            getCallType(this.partnerid).then((res) => {
                if (res.data.is_p2p == true) {
                    getMidByPid(this.spid).then((res) => {
                        let pmid = res.data.data.mid
                        if (this.sdid == 0 || !this.sdid) {
                            this.call_p2p(0, pmid, '')
                        } else {
                            getMidByDid(this.sdid).then((res) => {
                                this.call_p2p(1, pmid, res.data.data.mid)
                            })
                        }
                    })
                } else {
                    this.phone_status = 1
                    if (this.phone_status == 1) {
                        // 網頁版 先確認是否允許開啟鏡頭&麥克風
                        if (this.device == 3) {
                            navigator.mediaDevices
                                .getUserMedia({ video: true, audio: true })
                                .then((stream) => {
                                    this.sdid == 0 || !this.sdid
                                        ? this.callOut()
                                        : this.callAgent()
                                })
                                .catch((error) => {
                                    console.log('error', error)
                                    alert(this.$t('components.call.useMic'))
                                })
                        } else {
                            this.sdid == 0 || !this.sdid
                                ? this.callOut()
                                : this.callAgent()
                        }
                    }
                    setTimeout(() => {
                        this.phone_status = 0
                    }, 3000)
                }
            })
        },
        // 通話
        async callOut() {
            let payload = {
                queue_id: this.qid,
                calltype: 'video',
                from: this.device,
                spid: this.spid,
                partnerid: this.partnerid,
            }
            await this.$store
                .dispatch('callOut', payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        console.log('callout res', res)
                        this.$store.commit('SET_QUEUE_ID', res.data.qid)
                        this.$store.commit('SET_CALLING', true)
                        this.$store.dispatch('setRoomId', res.data.roomid)
                        this.$store.dispatch('updateDoctorStatus', 2000)
                        this.$socket.client.emit('join_room', {
                            room: res.data.roomid,
                        })
                        this.$store.commit('SET_ACTIVE_PATIENT_INFO', {
                            name: this.pname,
                            pid: this.spid,
                        })
                        this.$store.dispatch('toggleRightPanel', false)

                        let payload = {
                            isDev: this.isDev,
                            device: this.device,
                            room_id: res.data.roomid,
                            qid: res.data.qid,
                            did: res.data.did,
                            dname: res.data.dname,
                            doctorType: this.doctorType,
                            dmid: this.dmid,
                        }
                        this.$store.dispatch('enterRoom', payload)

                        // let payload2 = {
                        //     roomNumber: res.data.roomid,
                        //     queueID: res.data.qid.toString(),
                        //     scheme_time: (res.data.scheme_time=='')?0:res.data.scheme_time,
                        //     videoCall: 'true'
                        // };
                        // this.openStream(payload2, res.data.dname)

                        // 跳轉至病歷
                        if (
                            this.$route.path !=
                            '/emr2/' + this.spid + '/' + res.data.qid
                        ) {
                            this.$router.push(
                                '/emr2/' + this.spid + '/' + res.data.qid
                            )
                        }
                    } else if (res.data.status == 'ERROR') {
                        this.$store.dispatch('notify', {
                            color: 'danger',
                            title: 'API STATUS ERROR',
                            text: res.data.message,
                        })
                    }
                })
                .catch((e) => {
                    this.$store.dispatch('notify', {
                        color: 'danger',
                        title: 'API ERROR',
                    })
                    console.log('dispatch callOut 錯誤', e)
                })
        },
        // 通話 聯繫代理人
        async callAgent() {
            let payload = {
                devicetype: this.device,
                spid: this.spid,
                pname: this.pname,
                todid: this.sdid,
                dname: this.dname,
                date: this.currentday,
                partnerid: this.partnerid,
            }
            if (this.qid) {
                payload.qid = this.qid
            }
            await saveQueueANDMR(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        this.$store.commit('SET_CALLING', true)
                        this.$store.commit('SET_QUEUE_ID', res.data.qid)
                        this.$store.dispatch('setRoomId', res.data.roomId)
                        this.$store.dispatch('updateDoctorStatus', 2000)
                        this.$socket.client.emit('join_room', {
                            room: res.data.roomId,
                        })
                        this.$store.commit('SET_ACTIVE_PATIENT_INFO', {
                            name: this.pname,
                            pid: this.spid,
                        })
                        this.$store.dispatch('toggleRightPanel', false)

                        let payload = {
                            isDev: this.isDev,
                            device: this.device,
                            room_id: res.data.roomId,
                            qid: res.data.qid,
                            did: this.sdid,
                            dname: this.dname,
                            doctorType: this.doctorType,
                            dmid: this.dmid,
                        }
                        this.$store.dispatch('enterRoom', payload)

                        // let payload2 = {
                        //     roomNumber: res.data.roomid,
                        //     queueID: res.data.qid.toString(),
                        //     scheme_time: (res.data.scheme_time=='')?0:res.data.scheme_time,
                        //     videoCall: 'true'
                        // };
                        // this.openStream(payload2, res.data.dname)

                        // 跳轉至病歷
                        if (
                            this.$route.path !=
                            '/emr2/' + this.spid + '/' + res.data.qid
                        ) {
                            this.$router.push({
                                path: '/emr2/' + this.spid + '/' + res.data.qid,
                                query: {
                                    proxy_call_did: this.sdid,
                                    proxy_call_name: this.dname,
                                },
                            })
                        }
                    } else if (res.data.status == 'ERROR') {
                        this.$store.dispatch('notify', {
                            color: 'danger',
                            title: 'API STATUS ERROR',
                            text: res.data.message,
                        })
                    }
                })
                .catch((err) => {
                    this.$store.dispatch('notify', {
                        color: 'danger',
                        title: 'API ERROR',
                    })
                    console.log('err call out save mr and queue:: ', err)
                })
        },
        // p2p通話 is_doctor 0=民眾 1=醫事人員
        async call_p2p(is_doctor, pmid, dmid = 0) {
            console.log(
                'hereeeeeeeeeeeeeeeeeee is_doctor, pmid, dmid',
                is_doctor,
                pmid,
                dmid
            )
            let route = _.replace(
                _.lowerCase(_.split(this.$route.fullPath, '/', 2)[1]),
                ' ',
                ''
            )
            var payload = {
                partner_id: this.partnerid,
                is_doctor: is_doctor,
                to_mid: pmid,
                src: route,
                queue_id: this.qid,
            }

            if (dmid > 0) {
                payload['doc_mid'] = dmid
                var mid = dmid
            } else {
                var mid = pmid
            }

            if (this.snParams) payload.sn_id = this.snParams.id
            await call_p2p(payload).then((res) => {
                if (res.data.status == 'OK') {
                    // $('#p2p').show();
                    this.$store.commit('SET_CALL_ID', res.data.callId)
                    this.$store.commit(
                        'SET_LOCAL_ID',
                        'curdr_' + this.partnerid + '_' + this.dmid + '_1'
                    )
                    this.$store.commit(
                        'SET_REMOTE_ID',
                        'curdr_' + this.partnerid + '_' + mid + '_' + is_doctor
                    )
                    console.log(
                        'SET_LOCAL_ID',
                        'curdr_' + this.partnerid + '_' + this.dmid + '_1'
                    )
                    console.log(
                        'SET_REMOTE_ID',
                        'curdr_' + this.partnerid + '_' + mid + '_' + is_doctor
                    )
                    this.$store.commit('SET_CALLING', true)
                    this.$store.commit('SET_ROOM_ID', res.data.room_Id)
                    remoteID = res.data.remote_Id
                    console.log(
                        'callPoPup.vue call_p2p function remoteID => ',
                        remoteID
                    )
                    this.$store.dispatch('updateDoctorStatus', 2000)
                    p2p_call(
                        true,
                        res.data.room_Id,
                        res.data.callId,
                        res.data.remote_Id,
                        false,
                        res.data.caller,
                        true,
                        this.UUID
                    )
                    localStorage.setItem('p2p_queueid', res.data.queue_id)
                    console.log('hereeeeeeeeeeeee p2p_tomid', this.dmid)
                    localStorage.setItem('p2p_tomid', this.dmid)
                    let send_data = {
                        queue_id: res.data.queue_id,
                    }
                    p2pCallData = send_data
                    this.$emit('closePopup')
                    if (
                        this.$router.currentRoute.fullPath !=
                        '/emr2/' + this.spid + '/' + res.data.queue_id
                    ) {
                        this.$router.push(
                            '/emr2/' + this.spid + '/' + res.data.queue_id
                        )
                    }
                } else {
                    if (is_doctor == 0) {
                        this.$vs.notify({
                            color: 'danger',
                            title: 'ERROR',
                            text: '民眾未上線',
                        })
                    } else {
                        this.$vs.notify({
                            color: 'danger',
                            title: 'ERROR',
                            text: '醫師未上線',
                        })
                    }
                    console.log('call ERROR: ' + res.data.msg)
                }
            })
        },
        // 開啟視訊視窗
        // openStream(payload, dname){
        //     console.log("check payload", payload)
        //     // 網頁
        //     if(this.device==3){
        //         console.log("open stream on web")
        //         this.$store.dispatch('toggleRightPanel', true)
        //     }
        //     // android
        //     else if(this.device==2){
        //         console.log("open stream on android")
        //         payload.doctorName = dname
        //         payload.did = this.did
        //         console.log("check payload android", payload)
        //         window.android.callRTC(JSON.stringify(payload));
        //     }
        //     // ios
        //     else if(this.device==1){
        //         console.log("open stream on ios")
        //         payload.patientName = dname
        //         payload.photo = ''
        //         console.log("check payload ios", payload)
        //         window.webkit.messageHandlers.CallPatient.postMessage(JSON.stringify(payload));
        //     }
        // }
    },
}
</script>
